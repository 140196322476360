<template>
	<div class="container-fluid bg-white">
		<div class="row justify-content-center align-items-center">
			<div class="col-lg-8 mb-3 mb-lg-5 order-1 order-lg-0">
				<h1 class="display-4 text-center mt-lg-5 font-weight-bold">¡COMPRA TU CARRO!</h1>
				<div class="row justify-content-center">
					<div class="col-lg-8">
						<p class="h1 mt-3 mt-lg-5" style="font-weight: lighter;">La mejor opción para la compra de vehículos a través de un proceso 100% digital.</p>
					</div>
				</div>
				
				<div class="text-center mt-3 mt-lg-5">
					<button type="button" v-on:click="scrollToElement('scrollToMe', 'focusInput')" class="btn btn-secondary rounded-pill font-weight-bold px-5">
						Solicítalo
					</button>
				</div>
			</div>
			<div :class="{'col-lg-4 mb-3 mb-lg-5 p-0 order-0 order-lg-1':true, 'text-center':isMobile, 'text-right':!isMobile}">
				<img src="@/assets/image/prestamo-autocompras.webp" class="img-fluid" alt="Préstamo">
			</div>

			<div class="col-xl-8 col-lg-10 mb-4 order-2">
				<Cotizador v-model="monto" tipo="auto-compras" @enganche="engancheHandler" />
			</div>
		</div>
	</div>
	
    <div ref="scrollToMe" class="bg-white py-3">
        <div class="container">
            <div ref="landbotContainer" style="width: 100%; height: 100vh;"></div>
        </div>
    </div>

	<div class="container-fluid bg-secondary p-5">
		<BeneficiosPrestamos />
	</div>

	<div class="container-fluid bg-white">
		<div class="row align-items-center justify-content-center">
			<div class="col-lg-10 mt-5 mb-lg-5">
				<div class="row">
					<div class="col-lg-12 mb-5 mb-lg-3">
						<div class="row">
							<div class="col-lg-6">
								<h2 class="mb-2 mb-lg-4 font-weight-bold">¿Qué documentos necesitamos?</h2>
							</div>
							<div class="col-lg-6">
								<div class="accordion" id="accordionExample">
									<div class="" id="headingOne">
										<h2 class="mb-0">
											<button class="btn btn-block text-left d-flex justify-content-between align-items-center rounded-0 collapsed" style="border-bottom: 1px solid;" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
												<span class="h3 mb-0 text-primary">Requisitos</span>
												<img src="@/assets/image/down-arrow.webp" alt="flecha">
											</button>
										</h2>
									</div>

									<div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
										<div class="card-body">
											<div class="pr-md-5">
												<p class="font-weight-bold">Compra de vehículo en nuestra plataforma</p>

												<ul class="py-3">
													<li class="mb-2">Licencia de conducir vigente</li>
													<li class="mb-2">DPI vigente</li>
													<li class="mb-2">Recibo de agua, luz o teléfono fijo (minimo 2)</li>
													<li class="mb-2">Últimos 3 estados de cuenta</li>
													<li class="mb-2">Solicitud de financiamiento</li>
													<li class="mb-2">Estado patrimonial</li>
												</ul>
												<div class="d-flex align-items-center h-100" v-if="false">
												</div>

												<p class="font-weight-bold">Si el carro que deseas no esta en nuestra plataforma</p>

												<ul class="py-3">
													<li class="mb-2">(Todo lo anterior) y adicional a:</li>
													<li class="mb-2">Título de propiedad del vehículo</li>
													<li class="mb-2">Tarjeta de circulación</li>
													<li class="mb-2">Copia de llave de encendido del vehículo</li>
												</ul>

												<p class="text-primary">
													* Si el carro se encuentra a nombre de una empresa también podemos apoyarte, ponte en contacto con nosotros, será un gusto poder atenderte!
												</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-lg-12 mb-3">
						<div class="row">
							<div class="col-lg-6">
								<h2 class="mb-2 mb-lg-4 font-weight-bold">¿Porqué es mi mejor opción?</h2>
							</div>
							<div class="col-lg-6">
								<div class="accordion" id="accordionExample">
									<div class="" id="headingTwo">
										<h2 class="mb-0">
											<button class="btn btn-block text-left d-flex justify-content-between align-items-center rounded-0 collapsed" style="border-bottom: 1px solid;" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
												<span class="h3 mb-0 text-primary">Beneficios</span>
												<img src="@/assets/image/down-arrow.webp" alt="">
											</button>
										</h2>
									</div>

									<div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
										<div class="card-body">
											<div class="pr-md-5">
													<p>Puedes seleccionar un carro desde nuestro marketplace o elegir el que más te guste desde tu predio de confianza, nosotros te ofrecemos las mejores condiciones. Cuotas según tu capacidad de pago.</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="container-fluid bg-white my-5">
			<div class="row justify-content-center">
				<div class="col-lg-10">
					<h1 class="mt-5 mb-5 text-center display-4 font-weight-bold">Te compartimos más información</h1>
					<div class="row align-items-center justify-content-center pb-5">
						<div class="col-lg-5 mb-3">
							<div class="accordion" id="accordionInformacion">
								<div class="" id="headingQueEs">
									<h2 class="mb-0">
										<button class="btn btn-block text-left collapsed d-flex justify-content-between align-items-center rounded-0 mb-3" style="border-bottom: 1px solid;" type="button" data-toggle="collapse" data-target="#queEs" aria-expanded="false" aria-controls="queEs">
											<span class="h3 text-primary">¿Qué es AutoCompras?</span>
											<img src="@/assets/image/down-arrow.webp" alt="">
										</button>
									</h2>
								</div>

								<div id="queEs" class="collapse" aria-labelledby="headingQueEs" data-parent="#accordionInformacion">
									<div class="card-body">
										<div class="pr-md-5">
											<p>
												La mejor opción para comprar un carro nuevo o seminuevo con las mejores condiciones. 
											</p>
										</div>
									</div>
								</div>
								<div class="" id="headingFunciona">
									<h2 class="mb-0">
										<button class="btn btn-block text-left collapsed d-flex justify-content-between align-items-center rounded-0 mb-3" style="border-bottom: 1px solid;" type="button" data-toggle="collapse" data-target="#funciona" aria-expanded="false" aria-controls="funciona">
											<span class="h3 text-primary">¿Cómo funciona?</span>
											<img src="@/assets/image/down-arrow.webp" alt="">
										</button>
									</h2>
								</div>

								<div id="funciona" class="collapse" aria-labelledby="headingFunciona" data-parent="#accordionInformacion">
									<div class="card-body">
										<div class="pr-md-5">
											<ol>
												<li>
													Selecciona el carro que quieres desde nuestro marketplace o desde tu vendedor de confianza 
												</li>
												<li>
													Llena nuestro formulario de solicitud
												</li>
												<li>
													Nuestros asesores se comunicarán contigo para darle seguimiento a tu solicitud 
												</li>
												<li>
													Analizaremos tu solicitud
												</li>
												<li>
													Si aplicas y aceptas la propuesta, te damos el dinero para comprar el carro que deseas.
												</li>
											</ol>
										</div>
									</div>
								</div>

								<div class="" id="headingOpciones">
									<h2 class="mb-0">
										<button class="btn btn-block text-left collapsed d-flex justify-content-between align-items-center rounded-0 mb-3" style="border-bottom: 1px solid;" type="button" data-toggle="collapse" data-target="#opciones" aria-expanded="false" aria-controls="opciones">
											<span class="h3 text-primary">Opciones para comprar un carro</span>
											<img src="@/assets/image/down-arrow.webp" alt="">
										</button>
									</h2>
								</div>

								<div id="opciones" class="collapse" aria-labelledby="headingOpciones" data-parent="#accordionInformacion">
									<div class="card-body">
										<div class="pr-md-5">
											<ul>
												<li>
													Desde nuestro marketplace 
												</li>
												<li>
													Desde tu predio favorito
												</li>
												<li>
													Con tu vendedor de confianza 
												</li>
											</ul>
										</div>
									</div>
								</div>

								<div class="" id="headingClubCashin">
									<h2 class="mb-0">
										<button class="btn btn-block text-left collapsed d-flex justify-content-between align-items-center rounded-0 mb-3" style="border-bottom: 1px solid;" type="button" data-toggle="collapse" data-target="#clubCashin" aria-expanded="false" aria-controls="clubCashin">
											<span class="h3 text-primary">¿Qué es Clubcashin.com?</span>
											<img src="@/assets/image/down-arrow.webp" alt="">
										</button>
									</h2>
								</div>

								<div id="clubCashin" class="collapse" aria-labelledby="headingClubCashin" data-parent="#accordionInformacion">
									<div class="card-body">
										<div class="pr-md-5">
											<p>
												Somos la 1era Fintech p2p Lending de Guatemala, hemos otorgado millones de quetzales en préstamos a través de cientos de inversionistas. Impactamos en la vida de los guatemaltecos apoyándolos a cumplir sus sueños.
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-lg-7 text-center">
							<video class="rounded img-fluid" src="https://clubcashin.com/img/CashIn_Usuario.mp4" controls v-if="false">
								Tu navegador no soporta estos videos
							</video>
							<div class="embed-responsive embed-responsive-16by9">
								<iframe  class="rounded embed-responsive-item" src="https://www.youtube.com/embed/kC2y6E_Hx-Y?version=3&loop=1&playlist=kC2y6E_Hx-Y&controls=0&rel=0" title="Vende tu carro" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script type="text/javascript">
	import { defineAsyncComponent } from 'vue'

	const Cotizador = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "Cotizador" */"@/components/Cotizador.vue"),
		loadingComponent: {template:`<loading-component />`}
	})
	
	const BeneficiosPrestamos = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "PrestamosBeneficiosPrestamos" */"@/components/prestamos/BeneficiosPrestamos.vue"),
		loadingComponent: {template:`<loading-component />`}
	})

	export default {
		components: {
			Cotizador,
			BeneficiosPrestamos,
		},
		data(){
			return {
				tipoFormulario: false,

				monto: 1,
				enganche: 0,
				loadingVehiculos: false,
				validateForm: false,
			}
		},
		mounted(){
			if(this.sc && this.sc!=''){
				document.getElementById(this.sc).scrollIntoView({behavior: "smooth"})
			}

            const script = document.createElement('script');
            script.setAttribute('src', 'https://cdn.landbot.io/landbot-3/landbot-3.0.0.js');
            script.setAttribute('SameSite', 'None; Secure'); // Agregar el atributo SameSite
            script.async = true;
            script.onload = this.initializeLandbot;
            document.head.appendChild(script);
		},
		methods: {
            initializeLandbot() {
                new window.Landbot.Container({
                    container: this.$refs.landbotContainer,
                    configUrl: this.configUrl,
                });
            },

			engancheHandler(val){
				this.enganche = val
			},
		},
		computed: {
			query(){
				return this.$route.query || {}
			},
			sc(){
				return this.query.sc || ''
			},
			isMobile() {
				return this.$store.getters.isMobile
			},

			configUrl(){
				if(this.$route.name == 'leads.auto-compras2'){
					return 'https://storage.googleapis.com/landbot.online/v3/H-2147971-RVSNMDZU4HG6S1II/index.json'
				}
				return 'https://storage.googleapis.com/landbot.pro/v3/H-2205430-XMRRCT94RD9CJ3HC/index.json'
			}
		},
		watch:{
			sc(val){
				if (val) {
					document.getElementById(val).scrollIntoView({behavior: "smooth"})
				}
			}
		},
        beforeUnmount() {
            const scripts = document.getElementsByTagName('script');
            for (let i = 0; i < scripts.length; i++) {
                if (scripts[i].src === 'https://cdn.landbot.io/landbot-3/landbot-3.0.0.js') {
                    scripts[i].parentNode.removeChild(scripts[i]);
                }
            }
        }
	}
</script>